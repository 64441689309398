<template>
	<div class="app_main event_set">
		<div class="content_wrapper full_width" v-if="!loadingData.active">
			<InfoHeader
				v-bind="ticket"
				:refund="refund"
				:payout="payout"
				:printCopy="printCopy"
				:setTicket="setTicket"
				:setSidebar="setSidebar"
				:copyTicket="copyTicket"
				:cashoutAmount="cashoutAmount"
				:togglePromoPinModal="togglePromoPinModal"
			/>

			<div class="app_content flex_direction_rowrev">
				<!-- Events list -->
				<div class="ticket_event_list" style="flex-wrap: wrap;" @click="setSidebar(false)">
					<!-- If ticket is selected -->
					<table v-if="items && items.length" class="events">
						<component v-for="(item, i) in items" :key="item.key" :index="i" :is="item.type" v-bind="item" />
					</table>

					<!-- If ticket is loading or loading finished without the ticket -->
					<Loading v-else :error="!loading && $t('scanOrSelectTicket')" />
				</div>

				<!-- Ticket list -->
				<TicketList
					v-bind="history"
					:sidebar="sidebar"
					:selected="ticket"
					:setTicket="setTicket"
					:setSidebar="setSidebar"
				/>
			</div>

			<portal-target name="auth-confirmation" />

			<!-- Footer -->
			<div class="app_footer">
				<AuthorizationLine />
			</div>
		</div>
		<loading v-else :error="loadingData.error" :reload_btn_visible="true" />

		<PromoPinModal
			:closePromoPinModal="closePromoPinModal"
			:togglePromoPinModal="togglePromoPinModal"
			:refund="refund"
			:ticket="ticket"
		/>
	</div>
</template>

<script>
import { scrollTo, formatDate, selectDelayed, parseDynamicMessage } from '../utils';
import Loading from '../components/Loading';
import Odd from '../components/Tickets/Odd';
import Event from '../components/Tickets/Event';
import InfoHeader from '../components/Tickets/InfoHeader';
import TicketList from '../components/Tickets/TicketList';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { AleaControlShell } from '../utils/AleaControlShell';
import AuthorizationLine from '../components/AuthorizationLine';
import { toSnake, transformTicketForPrint, extractItems, getGeoParamsData, parse } from '../utils';
import { SENT, ACCEPTED, CASHBACK } from '../consts';
import PromoPinModal from '../components/Modals/PromoPinModal.vue';

export default {
	components: {
		Loading,
		odd: Odd,
		InfoHeader,
		TicketList,
		event: Event,
		AuthorizationLine,
		PromoPinModal,
	},
	data() {
		return {
			error: '',
			sidebar: true,
			loading: true,
			ticket: null,
			history: {
				loading: true,
				error: '',
				tickets: [],
			},
			watchedActions: ['cashoutPaidOut', 'cashoutAccepted'],
			unsubscribe: null,
			selectedDate: new Date(),
			appliedDate: null,
			closePromoPinModal: false,
		};
	},
	computed: {
		...mapGetters([
			'options',
			'calculator',
			'marketsMap',
			'loadingData',
			'isCheckedData',
			'userInfo',
			'isNotificationVisible',
			'offerPlans',
		]),
		barcode() {
			return this.$route.query.barcode;
		},
		events() {
			if (!this.ticket) return [];
			const nested = this.ticket.systems.map(({ events }) => events);
			return nested.flat();
		},
		items() {
			if (!this.ticket) return;
			const events = this.ticket.systems.map(({ events }) => events).flat();
			return events.reduce(extractItems, []);
		},

		cashoutAmount() {
			if (!this.ticket) return null;
			const cashoutActive = getGeoParamsData(['BETTING', 'CASH_OUT', 'CASH_OUT_ACTIVE']);
			if (!cashoutActive) return null;
			try {
				const {
					amount,
					combinations,
					systems,
					maxGain,
					status,
					cashout,
					ticketPin,
					resultStatus,
					winType,
					paidOut,
				} = this.ticket;
				//cashout can only be requested for single and multi tickets with status PLACED
				// and tickets that are not resulted or paid out
				if (
					!ticketPin ||
					combinations > 1 ||
					status !== 'PLACED' ||
					resultStatus != null ||
					winType != null ||
					paidOut
				)
					return null;
				if (cashout) {
					const { amount, authStatus, payoutStatus } = cashout;
					if (amount && authStatus === ACCEPTED && payoutStatus === SENT) return amount;
					if (authStatus === ACCEPTED && payoutStatus === ACCEPTED) return null;
				}
				const { events } = systems[0];
				let allOdds = [];
				//check is every event available in current offer, is there any bonus odds
				for (const event of events) {
					const { id: eventId, odds, listCode, live } = event;
					const oddsArray = Object.entries(odds).map((array) => ({ id: array[0], ...array[1] }));
					if (oddsArray.every(({ result }) => result != null)) {
						oddsArray.forEach((odd) => allOdds.push({ eventId: eventId, odd }));
					} else {
						const eventData = live
							? this.$store.getters?.liveEventData(listCode)
							: this.$store.getters?.prematchEventData(listCode);

						if (!eventData) {
							return null;
						}
						for (const odd of oddsArray) {
							const { id, bonusType, result } = odd;
							//if odd is not available in current offer, check if it is mapped to live odd
							if (!eventData.odds[id]) {
								const mappedOdd = eventData.odds[this.getMappedOddtype(id, eventData.sport.id)];
								if (
									!mappedOdd ||
									mappedOdd.value <= 1 ||
									(mappedOdd.manual_status != null && mappedOdd.manual_status != 'ACTIVE') ||
									mappedOdd.oddtype_status != 1
								)
									return null;
								allOdds.push({ eventId: eventId, odd: { ...mappedOdd, result } });
							} else {
								const { manual_status, oddtype_status, market_status, value } = eventData.odds[id];
								if (
									bonusType ||
									result === 0 ||
									oddtype_status !== 1 ||
									(manual_status != null && manual_status !== 'ACTIVE') ||
									market_status !== 'ACTIVE' ||
									value <= 1
								) {
									return null;
								}

								allOdds.push({
									eventId: eventId,
									odd: { ...eventData.odds[id], result },
								});
							}
						}
					}
				}
				if (!maxGain) return null;
				const availableQuota = +allOdds.reduce((accumulator, currentValue) => {
					const { odd } = currentValue;
					if (odd.result != 1) {
						accumulator *= odd.value;
					}
					return accumulator;
				}, 1)?.toFixed(2);
				const pendingQuotaFees = getGeoParamsData(['BETTING', 'CASH_OUT', 'CASH_OUT_PENDING_QUOTA_FEE']);
				const payInFees = getGeoParamsData(['BETTING', 'CASH_OUT', 'CASH_OUT_PAY_IN_FEE']);
				const pendingEventsFees = getGeoParamsData(['BETTING', 'CASH_OUT', 'CASH_OUT_PENDING_EVENTS_FEE']);
				const winningEventsFees = getGeoParamsData(['BETTING', 'CASH_OUT', 'CASH_OUT_WINNING_EVENTS_FEE']);

				const pendingQuotaFee = (
					(pendingQuotaFees || [])
						.sort((a, b) => b.pending_quota - a.pending_quota)
						.find(({ pending_quota }) => pending_quota <= availableQuota) || { fee: 0 }
				).fee;

				const payInFee = (
					(payInFees || []).sort((a, b) => b.pay_in - a.pay_in).find(({ pay_in }) => pay_in <= amount) || {
						fee: 1,
					}
				).fee;

				const pendingEventsNumber = events.reduce((accumulator, currentValue) => {
					const odd = Object.values(currentValue.odds)[0];
					if (odd.result === null) accumulator++;
					return accumulator;
				}, 0);

				const pendingEventsFee = (
					(pendingEventsFees || [])
						.sort((a, b) => b.pending_events - a.pending_events)
						.find(({ pending_events }) => pending_events <= pendingEventsNumber) || { fee: 1 }
				).fee;

				const winningPercentage = events.filter(({ odds }) => Object.values(odds)[0].result).length / events.length;

				const winningEventsPercentageFee = (
					(winningEventsFees || [])
						.sort((a, b) => b.winning_events_percentage - a.winning_events_percentage)
						.find(({ winning_events_percentage }) => winning_events_percentage <= winningPercentage) || {
						fee: 1,
					}
				).fee;
				const fee = pendingQuotaFee - payInFee - pendingEventsFee - winningEventsPercentageFee;
				const calculatedAmount = +((maxGain / availableQuota) * fee).toFixed(2);
				return calculatedAmount > 0 ? calculatedAmount : null;
			} catch (err) {
				console.error(err);
				return null;
			}
		},
	},
	methods: {
		...mapActions(['populateTicketWithCopy', 'resetTriggerStateAction', 'setTriggerTypeAction']),
		...mapMutations(['updateCalculator']),
		setSidebar(val) {
			if (typeof val !== 'function') this.sidebar = val;
			else this.sidebar = val(this.sidebar);
		},
		async setTicket(id, pin = null) {
			try {
				const ticket = pin ? await this.$api.getTicketByBarcode(id, pin) : await await this.$api.getTicket(id);
				const ids = ticket.systems
					.map(({ events }) => events)
					.flat()
					.map((event) => event.id);

				const data = await this.$api.getEventsResults({ events: ids });

				const extendedSystems = ticket.systems.map(({ events, required }) => {
					const extendedEvents = events.map((event) => {
						const resultData = data.find(({ id }) => id === event.id);
						return { ...event, result: resultData ? resultData.result.periodScores : [] };
					});
					return { events: extendedEvents, required };
				});

				this.ticket = { ...ticket, systems: extendedSystems, ticketPin: pin };

				selectDelayed('barcode', 100);
			} catch (err) {
				const errMsg =
					err && err.response && err.response.data && err.response.data.detail
						? err.response.data.detail
						: this.$t('checkTicketInfo');
				if (this.isNotificationVisible) return;
				this.$notifications.info({
					title: this.$t('ticketNotFound'),
					text: errMsg,
					type: 'error',
					data: { id: 'barcode' },
				});
			}
		},
		copyTicket() {
			this.populateTicketWithCopy(this.ticket);
		},
		printCopy() {
			if (!this.ticket) return;
			try {
				const transformedTicket = transformTicketForPrint(
					toSnake({
						...this.ticket,
						dt_placed: this.ticket.placedDatetime,
					}),
					this.marketsMap,
					true
				);
				if (this.ticket.paidInWithPromotion) transformedTicket.placedWithBonus = true;
				const ticketString = JSON.stringify(transformedTicket);

				AleaControlShell.setPrintText(ticketString, 'PosBettingTicket');

				this.$notifications.info({
					title: this.$t('ticketCopied'),
					type: 'success',
					data: { id: 'barcode' },
				});
			} catch (err) {
				this.$notifications.info({
					title: this.$t('ticketNotCopied'),
					type: 'error',
					data: { id: 'barcode' },
				});
			}
		},
		async refund(barcode, pin, promoPin) {
			let round = '/';
			const getOfferPlanName = Object.values(this.offerPlans).forEach((obj) => {
				if (obj.status === 'active') {
					round = obj.name
						.toLowerCase()
						.replace('plan', '')
						.trim();
				}
				return;
			});

			try {
				const { dtRefunded, promotionData } = await this.$api.refundTicket(barcode, pin, promoPin);
				this.loadHistory('skip');
				this.ticket.status = 'REFUNDED';

				// Update calculator
				if (this.options.calculatorEnabled) {
					const { numOfTickets, sum } = this.calculator;
					const newValues = { numOfTickets: numOfTickets - 1, sum: sum - this.ticket.originalAmount };
					this.updateCalculator(newValues);
				}

				const { barcode: code, amount, currency, ticket_type, original_amount } = transformTicketForPrint(
					toSnake(this.ticket),
					this.marketsMap
				);

				const refundedAmount = original_amount ? original_amount : amount;

				if (Object.keys(promotionData).length > 0) {
					const [_barcode, _pin] = promotionData?.redeem_code.split('+');

					promotionData.barcode = _barcode | '';
					promotionData.pin_code = _pin | '';
				}

				const language = localStorage.getItem('language') ?? '';
				const companyName =
					JSON.parse(localStorage.getItem('POS_device_info'))?.onlineInstanceParameters
						?.EMAIL_TEMPLATE_COMPANY_NAME ?? '';
				let promotionDataObj = {};

				if (Object.keys(promotionData).length > 0) {
					promotionDataObj = {
						Barcode: code,
						CompanyName: companyName,
						Round: round,
						PromotionPin: promotionData.redeem_code,
						PromotionName: promotionData.name[language] ?? '',
						PromotionNote: promotionData.description[language] ?? '',
						amount: promotionData.amount,
						promotionTag: promotionData.tag,
						PromotionPictureLink: promotionData.tag_image,
						PromotionPictureLink120: promotionData.tag_image_120,
					};
				}

				const date = new Date(dtRefunded + 'Z').toLocaleString();
				const refundSlipData = {
					barcode: code,
					date,
					amount: refundedAmount,
					currency,
					ticket_type,
					TicketPromotion: promotionDataObj,
				};
				const refundSlip = JSON.stringify(refundSlipData);
				AleaControlShell.setPrintText(refundSlip, 'PosBettingDeleteTicket');

				this.$notifications.info({
					title: this.$t('ticketRefunded'),
					text: [
						`${this.$t('ticketNumber')}: ${code}`,
						`${this.$t('payin')}: ${original_amount?.toFixed(2) || amount.toFixed(2)}  ${currency}`,
						`${this.$t('timePlaced')}: ${formatDate(
							this.ticket.placedDatetime,
							'hours:minutes date.month.year.'
						)}`,
						`${this.$t('timeStored')}: ${formatDate(dtRefunded, 'hours:minutes date.month.year.')}`,
					],
					type: 'success',
					data: { id: 'barcode', multiline: true },
				});
			} catch (err) {
				const {
					response: {
						data: { detail },
					},
				} = err;

				const errMsg = detail ?? message;

				this.$notifications.info({
					title: this.$t('ticketNotRefunded'),
					text: errMsg,
					type: 'error',
					data: { id: 'pin' },
				});
			}
		},

		async payout(barcode, pin, id) {
			try {
				const firstName = this.userInfo?.firstName;
				const lastName = this.userInfo?.lastName;
				const jmbg = this.userInfo?.jmbg;

				if (!jmbg) {
					const ticketPayoutRequiresPlayerData = await this.$api.makeCheckTicketPayoutRequest(id);

					if (ticketPayoutRequiresPlayerData === true) {
						window.AleaControlShell.openPlayerDetailsForm(`${barcode}+${pin}`);
						this.setTriggerTypeAction('payout');
						return;
					}
				}
				this.resetTriggerStateAction();

				const { dtPaidOut, taxAgencyUrl } = await this.$api.payOutTicket(barcode, pin, firstName, lastName, jmbg);

				this.loadHistory('skip');
				this.ticket.status = 'PAID_OUT';
				const { barcode: code, amount, gain, currency, ticket_type, resulted_quota } = transformTicketForPrint(
					toSnake(this.ticket),
					this.marketsMap
				);
				const date = new Date(dtPaidOut + 'Z').toLocaleString();
				if (this.ticket.winType === CASHBACK) {
					const payoutSlipData = {
						barcode: code,
						date,
						amount: this.ticket.originalGain ?? gain,
						amount_after_tax: gain,
						currency,
						resulted_quota,
						tax_value: +((this.ticket.originalGain ?? gain) - gain).toFixed(2),
						tax_agency_url: taxAgencyUrl,
					};
					const payoutSlip = JSON.stringify(payoutSlipData);
					AleaControlShell.setPrintText(payoutSlip, 'PosBettingCashBack');
				} else {
					const payoutSlipData = {
						barcode: code,
						date,
						amount: this.ticket.originalGain ?? gain,
						amount_after_tax: gain,
						currency,
						ticket_type,
						tax_value: +((this.ticket.originalGain ?? gain) - gain).toFixed(2),
						tax_agency_url: taxAgencyUrl,
					};
					const payoutSlip = JSON.stringify(payoutSlipData);
					AleaControlShell.setPrintText(payoutSlip, 'PosBettingConfirmTicket');
				}
				this.$notifications.info({
					title: this.$t('ticketPaidOut'),
					text: [
						`${this.$t('ticketNumber')}: ${code}`,
						`${this.$t('payin')}: ${(this.ticket.originalAmount && this.ticket.originalAmount.toFixed(2)) ||
							(amount && amount.toFixed(2))} ${currency}`,
						`${this.$t('payout')}: ${this.ticket && this.ticket.gain && this.ticket.gain.toFixed(2)} ${currency}`,
						`${this.$t('timePlaced')}: ${formatDate(
							this.ticket.placedDatetime,
							'hours:minutes date.month.year.'
						)}`,
						`${this.$t('payoutTime')}: ${formatDate(dtPaidOut, 'hours:minutes date.month.year.')}`,
					],
					type: 'success',
					data: { id: 'barcode', multiline: true },
				});

				this.setTicket(barcode, pin);
			} catch (err) {
				const {
					response: {
						data: { message, detail, dynamics },
					},
				} = err;
				const errMsg = message ?? detail;

				return this.$notifications.info({
					title: this.$t('ticketPayoutCurrentlyUnavailable'),
					text: [parseDynamicMessage(errMsg, dynamics)],
					type: 'warn',
					data: { id: 'barcode', multiline: true },
				});
			}
		},

		async loadHistory(selectIndex) {
			// Get list of last 20 placed tickets
			try {
				// Start the loader and get tickets
				this.history = { loading: true, error: '', tickets: null };

				const d = new Date();
				const date = d.getDate();
				const month = d.getMonth() + 1; // Since getMonth() returns month from 0-11 not 1-12
				const year = d.getFullYear();
				const deviceFilter = this.isCheckedData;

				const dateStr = year + '-' + month + '-' + date;
				let { tickets = [] } =
					(await this.$api.getTickets({
						limit: 20,
						statuses: ['PLACED', 'WINNING', 'LOSING'],
						request_date: dateStr,
						device_filter: deviceFilter,
					})) || {};
				tickets = tickets
					.filter(
						(ticket) => !ticket.authStatus || ticket.authStatus === 'ACCEPTED' || ticket.authStatus === 'MODIFIED'
					)
					.filter(
						({ cashout }) => !cashout || !(cashout.authStatus === ACCEPTED && cashout.payoutStatus === ACCEPTED)
					);

				this.history = { loading: false, error: '', tickets };
				if (!tickets.length) return;

				if (selectIndex !== undefined && selectIndex !== 'skip') {
					setTimeout(() => {
						this.setTicket(tickets[selectIndex].id);
						scrollTo(tickets[selectIndex].id);
					}, 250);
				} else if (selectIndex === 'skip') {
					return;
				} else if (!this.barcode) {
					setTimeout(() => this.setTicket(tickets[0].id), 250);
				}
			} catch (err) {
				this.history.error = err.message;
			}
		},

		getMappedOddtype(oddId, sportId) {
			if (!oddId || !sportId) return null;
			try {
				const { marketsMap } = this;
				const oddParts = oddId.split(':');
				const marketId = oddParts[0] + ':' + oddParts[1];
				const mappedOdd = marketsMap[sportId].markets.markets[marketId].oddtypes[oddId];
				return mappedOdd.id_mapped_oddtype || null;
			} catch (err) {
				return null;
			}
		},

		togglePromoPinModal() {
			return (this.closePromoPinModal = !this.closePromoPinModal);
		},
	},
	async created() {
		const { barcode, watchedActions } = this;

		// Fetch the scanned ticket
		try {
			if (barcode) {
				const [ticketNumber, pin] = barcode.split('+');
				this.sidebar = false;
				this.setTicket(ticketNumber, pin);
			}
		} catch (err) {
			this.error = err;
		} finally {
			this.loading = false;
		}

		this.loadHistory();

		this.unsubscribe = this.$store.subscribeAction((action) => {
			const { type, payload } = action;
			if (watchedActions.includes(type)) {
				const parsed = parse(payload[0]);
				const { id, cashout_amount, approved_amount } = parsed.data;
				if (this.ticket.id === id) {
					this.ticket = {
						...this.ticket,
						cashout: {
							authStatus: ACCEPTED,
							payoutStatus: ACCEPTED,
							amount: approved_amount || cashout_amount,
						},
					};
				}
			}
		});
	},

	watch: {
		isCheckedData() {
			this.loadHistory();
		},
	},

	destroyed() {
		this.unsubscribe && this.unsubscribe();
	},
};
</script>
